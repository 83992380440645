




































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import http from '@/shared/helpers/http'
import _ from 'lodash'
import __ from '@/helpers/__'
import IResponse from '@/shared/interfaces/response.interface'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { camelToSnakeCase } from '@/helpers/query'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import { IModalDialog } from '@/store/modules/modalDialog';
import Pagination from '@/shared/components/data-table/Pagination.vue';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import SelectOption from '@/shared/classes/components/form/select-option';
import { PerPageOptions } from '@/shared/configs/per-page-options';
import { tableCount } from '@/helpers/tableCount';
import { InvoiceStatusKeysDataPlural, OfferStatusKeys } from '@/shared/configs/offer.config';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';

@Component({
  components: { TableLoader, Pagination },
  methods: { __ }
})
export default class Table extends StickyTableHeader {
  @Prop() table!: DataTableBase
  @Prop() noRouterPagination!: boolean
  @Prop({ default: false }) hideSums?: boolean
  @Prop({ default: false }) simpleSums?: boolean
  @Prop({ default: false }) swapSums?: boolean

  defaultSort: Record<string, any> = {
    by: this.table.defaultSortBy || 'created_at',
    desc: true,
    page: 1
  }

  rerender: boolean = false
  total: number = 0
  sums: any = null
  sumsByStatus: any = null
  selectedSums: any = null
  page: number = this.defaultSort.page
  perPage: number = this.table.perPage
  sortBy: string = this.defaultSort.by
  sortDesc: boolean = this.defaultSort.desc
  perPageOptions: SelectOption[] = PerPageOptions
  toggleAllCheckboxValue: boolean = false
  showSelectedTotals: boolean = false
  $refs: any
  showTable: boolean = false
  tableReady: boolean = false
  tableHasData: boolean = false
  statusOptions: SelectOption[] = [
    new SelectOption().setKey(OfferStatusKeys.all).setTitle(InvoiceStatusKeysDataPlural[OfferStatusKeys.all]),
    new SelectOption().setKey(OfferStatusKeys.selected).setTitle(InvoiceStatusKeysDataPlural[OfferStatusKeys.selected]),
    new SelectOption().setKey(OfferStatusKeys.active).setTitle(InvoiceStatusKeysDataPlural[OfferStatusKeys.active]),
    new SelectOption().setKey(OfferStatusKeys.approved).setTitle(InvoiceStatusKeysDataPlural[OfferStatusKeys.approved]),
    new SelectOption().setKey(OfferStatusKeys.refused).setTitle(InvoiceStatusKeysDataPlural[OfferStatusKeys.refused]),
    new SelectOption().setKey(OfferStatusKeys.suspended).setTitle(InvoiceStatusKeysDataPlural[OfferStatusKeys.suspended])
  ]

  activeStatus: string = OfferStatusKeys.all

  @Watch('tableReady') onTableReady(newVal: boolean): void {
    if (newVal && this.tableHasData) { this.$emit('tableReady') }
  }

  @Watch('activeStatus') onStatusChange(newVal: string): void {
    if (newVal === OfferStatusKeys.selected) {
      this.showSelectedTotals = true
      return
    }
    this.showSelectedTotals = false
  }

  async created(): Promise<void> {
    if (this.table.id) {
      await tableCount.getRowCount(this.table.id).then((perPage: any) => {
        this.perPage = perPage
      })
    }
    this.showTable = true

    if ('page' in this.$route.query && this.$route.query.page !== this.defaultSort.page) {
      this.page = Number(this.$route.query.page)
    }

    this.setStickyHeader()
  }

  pageChange(page: number) {
    this.page = page
    this.refresh()
  }

  groupBy: string | string[] | null = null
  perPageLocalStorage: string = 'perPageRoutes'

  openModal(modalData: IModalDialog): void {
    this.$store.commit('setModalDialog', modalData)
  }

  itemsProvider() {
    const url = `${this.table.endpoint}?per_page=${this.perPage}${this.pageAndSortParams}${this.filterParams}${this.routeFilter}`
    this.table.setLoading(true)
    this.showSelectedTotals = false
    if (!this.table.endpoint) {
      this.total = this.table.data.length
      return this.table.data
    }

    return http.get(url).then((response: IResponse<IModelResponse[]>) => {
      const { data } = response
      const Model: any = this.table.model
      this.total = data.meta ? data.meta.total : null
      this.table.loading = false
      this.rerender = !this.rerender
      this.sums = response.data.sums
      this.selectedSums = response.data.sums
      this.sumsByStatus = response.data.sumsByStatus
      this.table.setLoading(false)

      const dataModeled = data.data.map((item: IModelResponse) => {
        return { ...(new Model(item)), selectedItem: false }
      })
      this.table.setData(dataModeled)
      this.tableHasData = !!dataModeled.length
      return dataModeled
    }).finally(() => {
      this.tableReady = true
    })
  }

  perPageChange(): void {
    if (this.table.id) {
      tableCount.setRowCount(this.table.id, this.perPage)
    }
    if (this.$route.query?.page) {
      this.$router.replace({ query: _.omit(this.$route.query, 'page') })
    }
    this.refresh()
  }

  refresh(): void {
    if (this.$refs.table) refreshTable(this.$refs.table)
  }

  getLinkParam(item: any, param: string) {
    return _.get(item, param)
  }

  getLinkName(row: any, link: any) {
    return _.get(row, link)
  }

  get selectedItems() {
    return this.table.data.filter((item: any) => {
      return item.selectedItem === true
    })
  }

  get selectedTotals() {
    let total = 0
    let totalWithVat = 0
    let totalVat = 0
    this.selectedItems.forEach((item) => {
      total += Number(item.total)
      totalWithVat += Number(item.totalWithVat)
      totalVat += Number(item.totalVat)
    })

    return {
      total: total.toFixed(2),
      totalWithVat: totalWithVat.toFixed(2),
      totalVat: totalVat.toFixed(2)
    }
  }

  get fields(): any {
    const fields = []

    if (this.table.sumCheckboxes) {
      fields.push(
        new DataTableHeader()
          .setKey('sumCheckboxes')
          .setSortable(false)
          .setLabel('')
      )
    }

    if (this.table.logo) {
      fields.push(
        new DataTableHeader()
          .setKey('logo')
          .setSortable(false)
          .setLabel('')
      )
    }

    fields.push(...this.table.headers.filter(header => header.visible))

    if (this.table.actions) {
      fields.push(
        new DataTableHeader()
          .setKey('actions')
          .setSortable(false)
          .setLabel('')
      )
    }

    return fields
  }

  get routeFilter(): string {
    let query: string = ''
    Object.keys(this.$route.query).forEach((key: string) => {
      if (key.startsWith('filter.')) {
        query += `&filter[${key.replace(/filter./g, '')}]=${this.$route.query[key]}`
      }
    })
    return query
  }

  get filterParams(): string {
    let query: string = ''
    this.table.filters.map((filter: any) => {
      if (!filter.field) query += `&filter[${filter.operator}.${filter.type}]=${filter.value}`
    })
    return query
  }

  get pageAndSortParams(): string {
    let query: string = ''

    if (_.has(this.$route.query, 'sort') && this.$route.query.sort !== this.defaultSort.by) {
      query += `&sort=${this.$route.query.sort}`
    } else query += `&sort=${this.defaultSort.desc ? '-' : ''}${this.defaultSort.by}`

    if (this.noRouterPagination) {
      query += `&page=${this.page}`
    } else if (_.has(this.$route.query, 'page')) {
      query += `&page=${this.$route.query.page}`
    } else query += `&page=${this.defaultSort.page}`

    if (_.has(this.$route.query, 'q')) {
      query += `&q=${this.$route.query.q}`
    }

    return query
  }

  sortingChanged(ctx: any): void {
    if (ctx.sortDesc === this.defaultSort.desc && ctx.sortBy === this.defaultSort.by) return
    this.$router.replace({
      query: Object.assign({
        ...this.$route.query,
        sort: (ctx.sortDesc ? '-' : '') + camelToSnakeCase(ctx.sortBy).split('.')[0]
      })
    })
  }

  get filtersCombined(): string {
    let filters: string = `${this.filterParams}${this.routeFilter}`
    if (filters.startsWith('&')) filters = filters.replace('&', '?')
    return filters
  }

  getData(data: any, id: string): any {
    return _.get(data, id)
  }

  getType(field: any): string {
    if (field.link) return 'router-link'

    if (field.hrefLink) return 'a'

    return 'span'
  }

  setRowClass(item: any): string {
    if (typeof this.table.rowClassCallback === 'function') {
      return this.table.rowClassCallback(item)
    }
    return ''
  }

  toggleAll(): void {
    const newValue = !this.toggleAllValue()
    this.table.data.forEach((item: any) => {
      item.selectedItem = newValue
    })
  }

  toggleAllValue(): boolean {
    const newVal = this.table.data.every((item: any) => item.selectedItem === true)
    setTimeout(() => {
      this.toggleAllCheckboxValue = newVal
    }, 30)

    return newVal
  }

  updateStatus(newStatus: string): void {
    if (newStatus === OfferStatusKeys.all || newStatus === OfferStatusKeys.selected) {
      this.selectedSums = this.sums
      return
    }
    const foundSum = this.sumsByStatus.find((sum: any) => {
      return sum.status && sum.status === newStatus
    })

    this.selectedSums = foundSum
  }
}
