









































import { Component, Vue, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import ClientInvoices from '@/modules/client/client-tabs/ClientInvoices.vue'
import ClientPrepaidInvoices from '@/modules/client/client-tabs/ClientPrepaidInvoices.vue'
import ClientPayments from '@/modules/client/client-tabs/ClientPayments.vue'
import ClientDocuments from '@/modules/client/client-tabs/ClientDocuments.vue'
import ClientOffers from '@/modules/client/client-tabs/ClientOffers.vue'
import ClientProfile from '@/modules/client/client-tabs/ClientProfile.vue'
import ClientInfo from '@/modules/client/ClientInfo.vue'
import Client from './models/client.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import ClientContacts from '@/modules/client/ClientContacts.vue';
import ClientRequests from '@/modules/client/client-tabs/ClientRequests.vue';
import ClientReminders from '@/modules/client/client-tabs/ClientReminders.vue';

@Component({
  components: {
    ClientReminders,
    ClientRequests,
    ClientContacts,
    PageHeader,
    ClientInfo,
    ClientInvoices,
    ClientPrepaidInvoices,
    ClientPayments,
    ClientDocuments,
    ClientOffers,
    ClientProfile
  },
  methods: { __ }
})
export default class ClientPage extends Vue {
  tabIndex: number = Number(this.$route?.query.tabIndex || 0)
  client: Client | any = null
  loading: boolean = true

  @Watch('tabIndex') private onTabChange(): void {
    this.$router.replace({ query: { tabIndex: this.tabIndex.toString() } })
  }

  mounted(): void {
    this.getData()
  }

  getData(): void {
    this.loading = true
    http
      .get(`/clients/${this.$route.params.id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.client = item.data
        this.$root.$emit('headingChange', {
          heading: this.client.company_name
        })
      })
      .finally(() => {
        this.loading = false
      })
  }
}
