












import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import DocumentTemplate from '@/modules/documents/models/document-template.model'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import DocumentTemplatesDelete from '@/modules/documents/DocumentTemplatesDelete.vue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class DocumentTemplatesIndex extends Vue {
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.documentsTemplatesIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.documentTemplates)
      .setModel(DocumentTemplate)
      .setEndpoint('/document-templates')
      .setRowClickCallback((item:DocumentTemplate) => {
        this.$router.push({ name: GeneralRoutes.documentsTemplatesInner, params: { id: (item.id || '').toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.documents.templates.index.table.columns.title'))
          .setLink({ link: GeneralRoutes.documentsTemplatesInner, linkParam: 'id' })
      ])
      .setActions([
        new DataTableAction()
          .setIcon('edit mr-2')
          .setAction(this.editTemplate)
          .setVisible((item: any) => !!item.title),
        new DataTableAction().setIcon('trash').setAction(this.openDeleteModal)
      ])
  }

  addNew(): void {
    this.$router.push({ name: GeneralRoutes.documentsTemplatesCreate })
  }

  editTemplate(item: any): void {
    this.$router.push({
      name: GeneralRoutes.documentsTemplatesEdit,
      query: this.$route.query,
      params: { id: item.item.id.toString() }
    })
  }

  openDeleteModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: DocumentTemplatesDelete,
      id: row.item.id,
      props: {
        title: __('views.documents.delete.title', { document: row.item.title }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }
}
