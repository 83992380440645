























































import { Component, Vue, Watch } from 'vue-property-decorator'
import ProcurementService from '@/services/ProcurementService';
import { IMeta } from '@/shared/interfaces/response.interface';
import ProcurementListItemData from '@/modules/procurement/procurement-list-item-data.interface';
import __ from '@/helpers/__';
import SelectOption from '@/shared/classes/components/form/select-option';
import ChoiceToggle from '@/shared/components/ChoiceToggle.vue';
import ProcurementList from '@/modules/procurement/ProcurementList.vue';
import moment from 'moment';
import _ from 'lodash';

enum timeOptionKeys {
  today = 'today',
  yesterday = 'yesterday',
  thisWeek = 'thisWeek',
  thisMonth = 'thisMonth',
  custom = 'custom'
}

@Component({
  components: { ProcurementList, ChoiceToggle },
  methods: { __ }
})

export default class ProcurementIndex extends Vue {
  procurementItems: ProcurementListItemData[] = []
  procurementsMeta: IMeta | null = null
  private savedOnly: boolean = false
  private timeFilter: string = ''
  private fromDate: string = ''
  private toDate: string = ''
  private showCustomDates: boolean = false
  private searchQuery: string = ''

  timeOptions: SelectOption[] = [
    new SelectOption().setKey(timeOptionKeys.today).setTitle(__('views.procurement.filter.today')),
    new SelectOption().setKey(timeOptionKeys.yesterday).setTitle(__('views.procurement.filter.yesterday')),
    new SelectOption().setKey(timeOptionKeys.thisWeek).setTitle(__('views.procurement.filter.thisWeek')),
    new SelectOption().setKey(timeOptionKeys.thisMonth).setTitle(__('views.procurement.filter.thisMonth')),
    new SelectOption().setKey(timeOptionKeys.custom).setTitle(__('views.procurement.filter.custom'))
  ]

  private debouncedSearch = _.debounce(() => { this.getProcurementItems('1') }, 400)

  created(): void {
    this.getProcurementItems(String(this.$route.query.page || 1))
  }

  @Watch('savedOnly')
  onSavedToggle(savedOnly: boolean) {
    if (savedOnly) {
      this.getSavedProcurementItems()
      return
    }
    this.getProcurementItems()
  }

  @Watch('timeFilter')
  onTimeFilterChange(key: string) {
    switch (key) {
      case timeOptionKeys.today:
        this.setDateFilter(moment().subtract(1, 'days'), moment())
        break;
      case timeOptionKeys.yesterday:
        this.setDateFilter(moment().subtract(2, 'days'), moment().subtract(1, 'days'))
        break;
      case timeOptionKeys.thisWeek:
        this.setDateFilter(moment().startOf('isoWeek').subtract(1, 'days'), moment())
        break;
      case timeOptionKeys.thisMonth:
        this.setDateFilter(moment().startOf('month').subtract(1, 'days'), moment())
        break;
      case timeOptionKeys.custom:
        this.setDateFilter(moment().subtract(1, 'days'), moment(), true)
        break;
      default:
        this.fromDate = ''
        this.toDate = ''
        this.showCustomDates = false
    }
  }

  @Watch('fromDate')
  onFromDateChange() {
    this.getProcurementItems('1')
  }

  @Watch('toDate')
  onToDateChange() {
    this.getProcurementItems('1')
  }

  @Watch('searchQuery')
  onSearchQueryChange() {
    this.debouncedSearch()
  }

  private setDateFilter(fromDate: moment.Moment, toDate: moment.Moment, showCustomDates: boolean = false) {
    this.fromDate = fromDate.format('YYYY-MM-DD')
    this.toDate = toDate.format('YYYY-MM-DD')
    this.showCustomDates = showCustomDates
  }

  private getProcurementItems(page: string = '1'): void {
    let dateRange: string | undefined
    if (this.fromDate !== '' && this.toDate !== '') {
      dateRange = `${this.fromDate}~${this.toDate}`
    }
    ProcurementService.getSavedCategoryProcurements(15, page, dateRange, this.searchQuery).then((response) => {
      this.procurementItems = response.data
      this.procurementsMeta = response.meta
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  }

  private getSavedProcurementItems(): void {
    ProcurementService.getSavedProcurements(999, 1).then((response) => {
      this.procurementItems = response.data
      this.procurementsMeta = response.meta
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  }
}
