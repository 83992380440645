





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TableLoader extends Vue {
  @Prop({ default: 10 }) rows!: number

  get loaderRows(): number {
    return this.rows < 20 ? this.rows : 20
  }
}
