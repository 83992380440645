







import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import Offer from '@/modules/offer/models/offer.model'
import { GeneralRoutes } from '@/router/routes/general'
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class OffersIndex extends Vue {
  table: DataTableBase | null = null

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.clientOffers)
      .setModel(Offer)
      .setEndpoint(`/clients/${this.$route.params.id}/offers`)
      .setRowClickCallback((item:Offer) => {
        this.$router.push({ name: GeneralRoutes.offer, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.offers.index.table.columns.title'))
          .setLink({ link: GeneralRoutes.offer, linkParam: 'id' }),
        new DataTableHeader().setKey('manager.full_name').setLabel(__('views.offers.index.table.columns.manager')),
        new DataTableHeader().setKey('date').setLabel(__('views.offers.index.table.columns.date')),
        new DataTableHeader().setKey('dueDate').setLabel(__('views.offers.index.table.columns.due_date')),
        new DataTableHeader().setKey('total').setLabel(__('views.offers.index.table.columns.total')),
        new DataTableHeader().setKey('totalWithVat').setLabel(__('views.offers.index.table.columns.total_with_vat'))
      ])
  }

  private addNewOffer() {
    this.$router.push({ name: GeneralRoutes.offersCreate, params: { clientId: this.$route.params.id } })
  }
}
