









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Toggle extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop() wrapperClass!: string

  private model: boolean | null = null

  @Watch('value', { immediate: true })
  onValueChange(newValue: boolean | null): void {
    this.model = newValue
  }

  private toggle(): void {
    this.model = !this.model
    this.$emit('input', this.model)
  }
}
