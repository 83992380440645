var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-20"},[_c('div',{staticClass:"d-flex justify-content-lg-end"},[_c('div',{staticClass:"invoice-buttons"},[_c('button',{staticClass:"button button--red button--has-icon button--delete mb-20 mr-md-10",on:{"click":function($event){$event.preventDefault();return _vm.openDeleteModal($event)}}},[_vm._v(" "+_vm._s(_vm.__('form.delete'))+" ")])])])]),_c('b-card',[(_vm.form && !_vm.loading)?_c('FormTemplate',{attrs:{"error":_vm.error},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('SearchableField',{attrs:{"id":"client_id","title":_vm.__('views.projects.form.client'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half,"loadItemsProps":{
          endpoint: 'clients',
          value: 'id',
          title: 'company_name'
          }},scopedSlots:_vm._u([{key:"notFoundComponent",fn:function(props){return [_c('ClientNotFound',{attrs:{"search-value":props.searchValue}})]}}],null,true),model:{value:(_vm.form.client_id),callback:function ($$v) {_vm.$set(_vm.form, "client_id", $$v)},expression:"form.client_id"}}),_c('TextField',{attrs:{"id":"url","title":_vm.__('views.projects.form.url'),"props":props,"size":_vm.FieldSizes.half},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),_c('DateField',{attrs:{"additional-input-class":"w-100","id":"date","title":_vm.__('views.projects.form.valid-till'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half},model:{value:(_vm.form.valid_until),callback:function ($$v) {_vm.$set(_vm.form, "valid_until", $$v)},expression:"form.valid_until"}}),_c('SelectField',{attrs:{"id":"status","props":props,"preset-value":_vm.form.status,"title":_vm.__('views.projects.form.status'),"options":_vm.statusOptions,"placeholder":"","required-decoration":true,"size":_vm.FieldSizes.half},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('SearchableField',{attrs:{"id":"cms","props":props,"title":_vm.__('views.projects.form.cms'),"required-decoration":true,"size":_vm.FieldSizes.half,"eager-loading":true,"loadItemsProps":{
          endpoint: 'projects/options',
          value: 'key',
          title: 'value'
          }},model:{value:(_vm.form.cms_type),callback:function ($$v) {_vm.$set(_vm.form, "cms_type", $$v)},expression:"form.cms_type"}}),_c('TextField',{attrs:{"id":"price","type":"number","title":_vm.__('views.projects.form.price'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half},model:{value:(_vm.form.month_price),callback:function ($$v) {_vm.$set(_vm.form, "month_price", $$v)},expression:"form.month_price"}}),_c('SelectField',{attrs:{"id":"type","props":props,"preset-value":_vm.form.type,"title":_vm.__('views.projects.form.type'),"options":_vm.typeOptions,"placeholder":"","required-decoration":true,"size":_vm.FieldSizes.half},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('RichEditorField',{attrs:{"id":"description","title":_vm.__('views.projects.form.comment'),"props":props,"size":_vm.FieldSizes.full},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})]}}],null,false,3184374960)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }