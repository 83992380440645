











import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import { InvoiceTemplateTable } from '@/modules/invoice-templates/models/invoice-template.model'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import InvoiceTemplateDelete from '@/modules/invoice-templates/InvoiceTemplatesDelete.vue';
import { tableCount } from '@/helpers/tableCount';
import Invoice from '@/modules/invoices/models/invoice.model';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class InvoiceTemplateIndex extends Vue {
  table: DataTableBase | any = null
  downloaded: boolean = true
  GeneralRoutes = GeneralRoutes

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.invoiceTemplatesIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.templateInvoicesIndex)
      .setModel(InvoiceTemplateTable)
      .setEndpoint('/invoice-templates')
      .setRowClickCallback((item:InvoiceTemplateTable) => {
        this.$router.push({ name: GeneralRoutes.invoiceTemplatesEdit, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('id')
          .setEntryKey('id')
          .setLabel(__('common-names.id'))
          .setLink({ link: GeneralRoutes.invoiceTemplatesEdit, linkParam: 'id' }),
        new DataTableHeader()
          .setKey('client.companyName')
          .setEntryKey('client.company_name')
          .setLabel(__('common-names.client')),
        new DataTableHeader()
          .setKey('manager.fullName')
          .setEntryKey('manager.full_name')
          .setLabel(__('common-names.manager')),
        new DataTableHeader()
          .setKey('generateEveryMonths')
          .setEntryKey('generate_every_months')
          .setLabel(__('views.invoice-templates.index.generate-every-months')),
        new DataTableHeader()
          .setKey('active')
          .setLabel(__('views.invoice-templates.index.active'))
          .setFunctionalClasses((data: Invoice) => `data-table__status data-table__status--${data.active}`),
        new DataTableHeader()
          .setKey('date')
          .setLabel(__('views.invoices.index.table.columns.justDate'))
          .setCustomText((data: Invoice) => {
            return data.date.split(' ')[0] || ''
          }),
        new DataTableHeader()
          .setKey('total')
          .setLabel(__('views.invoices.table.total-without-vat')),
        new DataTableHeader()
          .setKey('total_with_vat')
          .setLabel(__('views.invoices.table.total-with-vat'))
      ])
      .setActions([new DataTableAction().setIcon('trash').setAction(this.openDeleteModal)])
  }

  openDeleteModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: InvoiceTemplateDelete,
      id: row.item.id,
      props: {
        title: __('views.invoice-templates.delete.title'),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }

  addNew(): void {
    this.$router.push({ name: GeneralRoutes.invoiceTemplatesCreate })
  }
}
