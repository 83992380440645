



























































































































import { Component, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue';
import __ from '@/helpers/__';
import { GeneralRoutes } from '@/router/routes/general';
import { Route } from 'vue-router';
import http from '@/shared/helpers/http';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import IResponse, { IMeta } from '@/shared/interfaces/response.interface';
import IRequestItem from '@/modules/requests/interfaces/request-item.interface';
import IRequestResponse from '@/modules/requests/interfaces/request-response.interface';
import Pagination from '@/shared/components/data-table/Pagination.vue';
import Filters from '@/shared/components/data-table/Filters.vue';
import moment from 'moment/moment';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import SelectOption from '@/shared/classes/components/form/select-option';
import _ from 'lodash';
import { PerPageOptions } from '@/shared/configs/per-page-options';
import { tableCount } from '@/helpers/tableCount';
import FormField from '@/shared/components/form/FormField.vue';
import Field, { FieldSizes } from '@/shared/classes/components/form/field';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';
import { Container, Draggable } from 'vue-dndrop';
import ChoiceToggle from '@/shared/components/ChoiceToggle.vue';
import RequestService from '@/services/RequestService';

interface TableField {
  key: string
  label: string
  sortable: boolean
}

@Component({
  components: { ChoiceToggle, FormField, TableLoader, Filters, Pagination, PageHeader, Container, Draggable },
  methods: { __ }
})
export default class RequestIndex extends StickyTableHeader {
  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.requestIndex && this.$refs.itemsTable) {
      this.fetchRequests()
    }
  }

  private fields: TableField[] = [
    { key: 'source', label: __('views.requests.form.source'), sortable: true },
    { key: 'name', label: __('views.requests.form.client'), sortable: true },
    { key: 'createdAt', label: __('views.requests.form.date'), sortable: true },
    { key: 'phone', label: __('views.requests.form.phone'), sortable: true },
    { key: 'email', label: __('views.requests.form.email'), sortable: true },
    { key: 'description', label: __('views.requests.form.description'), sortable: true },
    { key: 'manager', label: __('views.requests.form.manager'), sortable: true },
    { key: 'status', label: __('views.requests.form.status'), sortable: true }
  ]

  private perPageOptions: SelectOption[] = PerPageOptions
  private perPage = 10
  private items: IRequestItem[] = []
  private meta: IMeta | undefined | null = null
  private loading: boolean = true
  GeneralRoutes = GeneralRoutes
  allowHover: boolean = true
  enableDragging: boolean = false

  searchField: Field = new Field()
    .setKey('q')
    .setSize(FieldSizes.null)
    .setPlaceholder('Paieška')

  get searchQuery(): any {
    let searchObject = {}
    if (this.searchField.value) {
      searchObject = {
        q: this.searchField.value
      }
    }
    return searchObject
  }

  @Watch('searchQuery', { deep: true })
  private onFilterChange(): void {
    this.debouncedSearch()
  }

  private debouncedSearch = _.debounce(this.search, 400)

  search(): void {
    this.$router.replace({ query: Object.assign({ ...this.searchQuery }) })
  }

  onDrop(dropResult: any) {
    this.allowHover = true
    const item = this.items.splice(dropResult.removedIndex, 1)[0]
    this.items.splice(dropResult.addedIndex, 0, item)
    let metaOffset = 0
    if (this.meta) {
      metaOffset = Number(this.meta.current_page - 1) * Number(this.meta.per_page)
    }
    RequestService.updateSequence(dropResult.addedIndex + metaOffset, item.id)
  }

  onDropReady() {
    this.allowHover = false
  }

  created(): void {
    tableCount.getRowCount(tableCount.tableID.requestsIndex).then((perPage: any) => {
      this.perPage = perPage
      this.fetchRequests()
    })

    this.$root.$on('formSuccess', () => {
      this.fetchRequests()
    })
  }

  perPageChange(): void {
    tableCount.setRowCount(tableCount.tableID.requestsIndex, this.perPage)
    if (this.$route.query?.page) {
      this.$router.replace({ query: _.omit(this.$route.query, 'page') })
    }
    this.fetchRequests()
  }

  private fetchRequests(): void {
    this.loading = true
    const page = this.$route.query.page || 1
    let params: any = { per_page: this.perPage, page, sort: 'sequence' }
    if (this.searchQuery?.q) {
      params = { ...params, q: this.searchQuery.q }
    }
    http
      .get('/requests', { params: params })
      .then((response: IResponse<IModelResponse>) => {
        this.meta = response.data.meta
        const processedItems: IRequestItem[] = []
        response.data.data.forEach((item: IRequestResponse) => {
          processedItems.push({
            id: item.id,
            source: item.source_title,
            name: item.client_name,
            phone: item.client === null ? item.phone : item.client.phone,
            email: item.client === null ? item.email : item.client.company_email,
            description: this.trimText(item.description),
            manager: item.manager === null ? '' : item.manager.full_name,
            statusTitle: item.status_title,
            status: item.status,
            createdAt: moment(item.created_at).format('YYYY-MM-DD')
          })
        })
        this.items = processedItems
      })
      .finally(() => {
        this.loading = false
        this.setStickyHeader()
      })
  }

  createNew(): void {
    this.$router.push({ name: GeneralRoutes.requestCreate })
  }

  private trimText(text: string): string {
    text = text
      .replace(/(<([^>]+)>)/gi, '').replace(new RegExp('&nbsp;', 'g'), ' ')
      .replace(new RegExp('&scaron;', 'g'), 'š')
      .replace(new RegExp('&Scaron;', 'g'), 'Š')
    if (text.length > 100) {
      return text.slice(0, 96) + '...'
    }
    return text
  }

  private rowClick(item: IRequestItem): void {
    this.$router.push({ name: GeneralRoutes.requestInner, params: { id: item.id.toString() } })
  }
}
