












import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import Service from '@/modules/service/models/service.model'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import ServicesCreate from '@/modules/service/ServicesCreate.vue';
import ServicesEdit from '@/modules/service/ServicesEdit.vue';
import ServicesDelete from '@/modules/service/ServicesDelete.vue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { ServicesCreate, DataTable, PageHeader },
  methods: { __ }
})
export default class ServicesIndex extends Vue {
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.servicesIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.servicesIndex)
      .setModel(Service)
      .setEndpoint('/services')
      .setRowClickCallback((item:Service) => {
        this.openEditModal(item)
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.services.index.table.columns.name'))
          .setClasses('c-blue cursor-pointer')
          .setOnClick(this.openEditModal),
        new DataTableHeader()
          .setKey('amount')
          .setLabel(__('views.services.index.table.columns.amount')),
        new DataTableHeader().setKey('pricePerUnit').setLabel(__('views.services.index.table.columns.price')),
        new DataTableHeader().setKey('unit').setLabel(__('views.services.index.table.columns.unit')),
        new DataTableHeader().setKey('vat').setLabel(__('views.services.index.table.columns.vat')),
        new DataTableHeader()
          .setKey('pricePerUnitWithVat')
          .setLabel(__('views.services.index.table.columns.price-per-unit-with-vat'))
      ])
      .setActions([new DataTableAction().setIcon('trash').setAction(this.openDeleteModal)])
  }

  openAddNewModal():void {
    this.$store.commit('setModalDialog', {
      component: ServicesCreate,
      props: {
        title: __('views.services.create.title')
      }
    })
  }

  openEditModal(item: Service): void {
    this.$store.commit('setModalDialog', {
      component: ServicesEdit,
      id: item.id,
      props: {
        title: __('views.services.edit.title', { service: item.title }),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }

  openDeleteModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: ServicesDelete,
      id: row.item.id,
      props: {
        title: __('views.services.delete.title', { service: row.item.title }),
        size: 'md',
        submitLabel: __('form.delete')
      }
    })
  }
}
