














































































































































import { Component, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import PrepaidInvoice from '@/modules/prepaid-invoices/models/prepaid-invoice.model'
import { GeneralRoutes } from '@/router/routes/general'
import { Route } from 'vue-router'
import Payment from '../payment/models/payment.model'
import moment from 'moment'
import fileBuffer from '@/shared/helpers/file-buffer-helper'
import { InvoiceStatusKeysData } from '@/shared/configs/invoice/invoice.config';
import PaymentsCreate from '@/modules/payment/PaymentsCreate.vue';
import PrepaidInvoiceDelete from '@/modules/prepaid-invoices/PrepaidInvoicesDelete.vue';
import fetchInvoiceData from '@/shared/helpers/fetch-invoice-data';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import PrepaidInvoiceServicesTable from '@/modules/prepaid-invoices/tables/PrepaidInvoiceServicesTable.vue';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';

interface fieldType {
  key: string
  label: string
  sortable?: boolean
}

@Component({
  components: { PrepaidInvoiceServicesTable, TableLoader, PaymentsCreate, PageHeader },
  methods: { __ }
})
export default class PrepaidInvoicePage extends StickyTableHeader {
  invoice: PrepaidInvoice | any = null
  downloaded: boolean = true
  loading: boolean = true
  GeneralRoutes = GeneralRoutes

  fields: fieldType[] = [
    { key: 'title', label: __('views.invoices.table.title'), sortable: true },
    { key: 'total', label: __('views.invoices.table.total'), sortable: true }
  ]

  paymentFields: fieldType[] = [
    { key: 'date', label: __('views.invoices.table.date'), sortable: true },
    { key: 'full_payment', label: __('views.invoices.table.full-payment'), sortable: true },
    { key: 'amount', label: __('views.invoices.table.amount'), sortable: true },
    { key: 'notes', label: __('views.invoices.table.notes'), sortable: true }
  ]

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.prepaidInvoice && this.$refs.paymentsTable && this.$refs.itemsTable) {
      this.getData()
    }
  }

  created(): void {
    fetchInvoiceData(this, 'standard', GeneralRoutes.invoice)
      .then((item) => {
        this.invoice = this.formatData(item.data.data)

        this.$root.$emit('headingChange', {
          heading: __('views.invoices.invoice.title', { invoices: this.invoice.number })
        })
      })
      .finally(() => {
        this.loading = false
        this.setStickyHeader()
      })
  }

  getData(): void {
    this.loading = true
    http
      .get(`/invoices/${this.$route.params.id}`)
      .then((item: IResponse<IModelResponse>) => {
        this.invoice = this.formatData(item.data.data)
      })
      .finally(() => {
        this.loading = false
      })
  }

  editInvoice(): void {
    this.$router.push({ name: GeneralRoutes.prepaidInvoicesEdit, params: { id: this.$route.params.id } })
  }

  openPaymentModal(): void {
    this.$store.commit('setModalDialog', {
      component: PaymentsCreate,
      id: this.invoice.id,
      props: {
        title: __('views.payments.create.title', { invoice: this.invoice.number })
      },
      details: {
        clientID: this.invoice.client_id,
        totalWithVat: this.invoice.total_with_vat,
        leftToPay: this.invoice.left_pay
      }
    })
  }

  openDeleteModal(): void {
    this.$store.commit('setModalDialog', {
      component: PrepaidInvoiceDelete,
      id: this.$route.params.id,
      props: {
        title: __('views.prepaid-invoices.delete.title', { invoice: this.invoice.number }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }

  downloadPDF(): void {
    this.downloaded = false
    http
      .get(`invoice-pdf/${this.$route.params.id}`, { responseType: 'blob' })
      .then(response => {
        fileBuffer.downloadFileBuffer(response)
      })
      .finally(() => {
        this.downloaded = true
      })
  }

  formatData(data: PrepaidInvoice): any {
    return {
      ...data,
      payments: data.payments.map((payment: Payment) => ({
        ...payment,
        date: moment(payment.date).format('YYYY-MM-DD')
      }))
    }
  }

  invoiceStatus(statusKey: string): string {
    return InvoiceStatusKeysData[statusKey]
  }
}
