// @ts-nocheck
import variables from '@/assets/styles/sass/common/_variables.scss'
import __ from '@/helpers/__'

export const randomizeArray = function(arg) {
  const array = arg.slice()
  let currentIndex = array.length
  let temporaryValue = array.length
  let randomIndex = array.length

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const getGraphInfo = function(data: Record<string, number>) {
  return {
    series: [
      {
        name: __('common-names.sum'),
        data: Object.keys(data).map((key: string) => data[key])
      }
    ],

    chartOptions: {
      chart: {
        height: '100%',
        type: 'area',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },

      xaxis: {
        type: 'date',
        categories: Object.keys(data).map((key: string) => key)
      },
      tooltip: {
        x: {
          format: 'yyyy/MM/dd'
        }
      },
      colors: [variables.blue]
    }
  }
}
