import IDashboardInfoResponse from '../interfaces/dashboard-response.interface'

export default class DashboardInfo {
  static ALIAS: string = 'dashboard-info'

  thisMonthOfferCount: number
  thisMonthOfferCountForUser: Record<string, number>
  paidInvoiceMonthlySumsWithoutVat: Record<string, number>
  thisYearPaidInvoiceSumsWithVat: number
  unpaidInvoiceSumsWithVat: number
  unpaidInvoiceSumsWithVatAfterDate: number
  thisMonthPaymentSums: number
  lastMonthVatSums: number
  thisMonthVatSums: number

  constructor(data: IDashboardInfoResponse) {
    this.thisMonthOfferCount = data.this_month_offer_count
    this.thisMonthOfferCountForUser = data.this_month_offer_count_for_user
    this.paidInvoiceMonthlySumsWithoutVat = data.paid_invoice_monthly_sums_without_vat
    this.thisYearPaidInvoiceSumsWithVat = data.this_year_paid_invoice_sums_with_vat
    this.unpaidInvoiceSumsWithVat = data.unpaid_invoice_sums_with_vat
    this.unpaidInvoiceSumsWithVatAfterDate = data.unpaid_invoice_sums_with_vat_after_date
    this.thisMonthPaymentSums = data.this_month_payment_sums
    this.lastMonthVatSums = data.last_month_vat_sums
    this.thisMonthVatSums = data.this_month_vat_sums
  }
}
