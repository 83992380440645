



import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Client from '@/modules/client/models/client.model'
import { HttpMethod } from '@/helpers/requests'

@Component({
  components: { Form },
  methods: { __ }
})
export default class ClientProfile extends Vue {
  client: Client | null = null
  form: FormBase = new FormBase()
    .setEndpoint('/clients')
    .setSuccessAlert('Naudotojo informacija išsaugota')
    .setMethod(HttpMethod.PUT)
    .setUuid(this.$router.currentRoute.params.id)
    .setOnSetEntry((client: any) => {
      this.client = client
    })
    .setModel(Client)
    .setOnSuccess(() => {
      this.$emit('profile_update')
    })
    .setFields([
      new Field()
        .setKey('company_name')
        .setEntryKey('companyName')
        .setTitle(__('views.clients.form.company_name'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_address')
        .setEntryKey('companyAddress')
        .setTitle(__('views.clients.form.company_address'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_code')
        .setEntryKey('companyCode')
        .setTitle(__('views.clients.form.company_code'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_vat_code')
        .setEntryKey('companyVatCode')
        .setTitle(__('views.clients.form.company_vat_code'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_director')
        .setEntryKey('companyDirector')
        .setTitle(__('views.clients.form.director'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('contact_full_name')
        .setEntryKey('contactFullName')
        .setTitle(__('views.clients.form.contact_full_name'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('phone')
        .setTitle(__('views.clients.form.phone'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.email)
        .setKey('company_email')
        .setEntryKey('companyEmail')
        .setTitle(__('views.clients.form.company_email'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.email)
        .setKey('reminder_email')
        .setEntryKey('reminderEmail')
        .setTitle(__('views.clients.form.reminder_email'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('send_reminders')
        .setEntryKey('sendReminders')
        .setTitle(__('views.clients.form.send_reminders'))
        .setSize(FieldSizes.half)
    ])
}
