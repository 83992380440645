












import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import User from '@/modules/user/models/user.model'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import DataTableLogo from '@/shared/classes/components/data-table/data-table-logo'
import UsersCreate from '@/modules/user/UsersCreate.vue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class UsersIndex extends Vue {
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.usersIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.usersIndex)
      .setModel(User)
      .setEndpoint('/users')
      .setRowClickCallback((item:User) => {
        this.$router.push({ name: GeneralRoutes.user, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('fullName')
          .setLabel(__('views.users.index.table.full_name'))
          .setClasses('fullName')
          .setLink({ link: GeneralRoutes.user, linkParam: 'id' }),
        new DataTableHeader()
          .setKey('position')
          .setEntryKey('position')
          .setLabel(__('views.users.index.table.position')),
        new DataTableHeader()
          .setKey('email')
          .setEntryKey('email')
          .setLabel(__('views.users.index.table.email'))
          .setHrefLink((data: User) => `mailto:${data.email}`),
        new DataTableHeader()
          .setKey('phone')
          .setEntryKey('phone')
          .setLabel(__('views.users.index.table.phone'))
          .setHrefLink((data: User) => `tel:${data.phone}`),
        new DataTableHeader()
          .setKey('disabled')
          .setEntryKey('disabled')
          .setLabel(__('views.users.index.table.disabled'))
          .setFunctionalClasses(
            (data: User) => `data-table__status data-table__status--${data.disabled ? 'inactive' : 'active'}`
          )
          .setCustomText((data: User) => (data.disabled ? __('common-names.inactive') : __('common-names.active')))
      ])
      .setLogo(
        new DataTableLogo()
          .setKey('userLogo')
          .setLogo(() => null)
          .setInitials((data: User) => data.firstName.substring(0, 1) + data.lastName.substring(0, 1) || null)
      )
  }

  openAddNewModal():void {
    this.$store.commit('setModalDialog', {
      component: UsersCreate,
      props: {
        title: __('views.users.create.title'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }
}
