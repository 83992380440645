





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ProcurementSelect from '@/modules/procurement/ProcurementSelect.vue';
import { IMeta } from '@/shared/interfaces/response.interface';
import Pagination from '@/shared/components/data-table/Pagination.vue';
import ProcurementListItem from '@/modules/procurement/ProcurementListItem.vue';
import ProcurementService from '@/services/ProcurementService';
import ProcurementListItemData from '@/modules/procurement/procurement-list-item-data.interface';
import ProcurementSavePayload from '@/modules/procurement/interfaces/procurement-save-payload.interface';
import __ from '@/helpers/__';
import ChoiceToggle from '@/shared/components/ChoiceToggle.vue';
import ProcurementListLoader from '@/modules/procurement/ProcurementListLoader.vue';

@Component({
  components: { ProcurementListLoader, ChoiceToggle, ProcurementListItem, Pagination, ProcurementSelect },
  methods: { __ }
})
export default class ProcurementList extends Vue {
  @Prop() data?: ProcurementListItemData[]
  @Prop() meta?: IMeta
  @Prop() canSave?: boolean

  private savedProcurements: number[] = []
  private loading: boolean = true

  created(): void {
    if (this.canSave) {
      ProcurementService.getSavedProcurements(999)
        .then((response) => {
          response.data.forEach((item: ProcurementListItemData) => {
            this.savedProcurements.push(item.id)
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  @Watch('$route.query.page') private onPageChange(newVal: string): void {
    if (typeof newVal !== 'undefined') {
      this.$emit('procurementListPageChange', newVal)
    }
  }

  private updateSavedProcurements(data:ProcurementSavePayload): void {
    if (data.is_memorized) {
      this.savedProcurements.push(data.procurement_id)
      return
    }
    const procurementIndex = this.savedProcurements.indexOf(data.procurement_id);
    if (procurementIndex > -1) {
      this.savedProcurements.splice(procurementIndex, 1)
    }
  }
}
