

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Pagination extends Vue {
  @Prop({ default: 0 }) total!: number
  @Prop({ default: 1 }) page!: number
  @Prop({ default: 10 }) perPage!: number
  @Prop() wrapperClass!: string
  @Prop({ default: false }) noRouterChange!: boolean

  private currentPage = 1

  created(): void {
    this.currentPage = this.page
  }

  @Watch('$route.query.page')
  onPageQueryChange(newValue: number): void {
    this.currentPage = newValue || 1
  }

  onPageChange(value: any): void {
    if (this.noRouterChange) {
      this.$emit('pageChange', value)
      return
    }
    this.$router.replace({ query: Object.assign({ ...this.$route.query, page: value }) })
  }
}
